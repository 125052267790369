import React from "react";
import { gameRule } from "../../utils/mock";

const GameRule = () => {

    return (
        <>
            <div className="container">
                <div className="policy_text">
                    <div className="row justify-content-center my-2">
                        <div className="col-lg-6 col-md-10 text-center">
                            <div className="commantext">
                                <h4>Contest Rules and Format</h4>
                            </div>
                        </div>
                    </div>
                    <div>
                        {gameRule.map((section, index) => (
                            <div className="commanOther" key={index}>
                                <h4>{section.title}</h4>
                                <p style={{ whiteSpace: "pre-wrap" }}>{section.content}</p>

                            </div>
                        ))}
                    </div>
                    <div>
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th width="15%">Title</th>
                                <th width="10%">T10</th>
                                <th width="10%">T20</th>
                                <th width="10%">ODI</th>
                                <th width="10%">Test</th>
                                <th width="45%">Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td width="15%">General</td>
                                <td width="10%">4</td>
                                <td width="10%">2.00</td>
                                <td width="10%">2.00</td>
                                <td width="10%">2.00</td>
                                <td width="45%">Being a part of the starting XI</td>
                            </tr>
                            <tr>
                                <td width="15%">General</td>
                                <td width="10%">1</td>
                                <td width="10%">0.5</td>
                                <td width="10%">0.5</td>
                                <td width="10%">0.5</td>
                                <td width="45%">Every run scored</td>
                            </tr>
                            <tr>
                                <td width="15%">General</td>
                                <td width="10%">25</td>
                                <td width="10%">10.0</td>
                                <td width="10%">12.0</td>
                                <td width="10%">8.00</td>
                                <td width="45%">Every wicket taken (excluding run out)</td>
                            </tr>
                            <tr>
                                <td width="15%">General</td>
                                <td width="10%">8</td>
                                <td width="10%">4.00</td>
                                <td width="10%">4.00</td>
                                <td width="10%">4.00</td>
                                <td width="45%">Fielding points</td>
                            </tr>
                            <tr>
                                <td width="15%">General</td>
                                <td width="10%">-2</td>
                                <td width="10%">-2</td>
                                <td width="10%">-3</td>
                                <td width="10%">-4</td>
                                <td width="45%">Dismissal for a Duck (only for batsmen, wicket-keepers and
                                    all-rounders)
                                </td>
                            </tr>
                            <tr>
                                <td width="15%">Bonus Points</td>
                                <td width="10%">1</td>
                                <td width="10%">0.5</td>
                                <td width="10%">0.5</td>
                                <td width="10%">0.5</td>
                                <td width="45%">Every boundary hit</td>
                            </tr>
                            <tr>
                                <td width="15%">Bonus Points</td>
                                <td width="10%">2</td>
                                <td width="10%">1</td>
                                <td width="10%">1</td>
                                <td width="10%">1</td>
                                <td width="45%">Every six hit</td>
                            </tr>
                            <tr>
                                <td width="15%">Bonus Points</td>
                                <td width="10%">16</td>
                                <td width="10%">4</td>
                                <td width="10%">2</td>
                                <td width="10%">2</td>
                                <td width="45%">Half Century (50 runs scored by a batsman in a single innings)</td>
                            </tr>
                            <tr>
                                <td width="15%">Bonus Points</td>
                                <td width="10%">NA</td>
                                <td width="10%">8</td>
                                <td width="10%">4</td>
                                <td width="10%">4</td>
                                <td width="45%">Century (100 runs scored by a batsman in a single innings)</td>
                            </tr>
                            <tr>
                                <td width="15%">Bonus Points</td>
                                <td width="10%">16</td>
                                <td width="10%">2</td>
                                <td width="10%">2</td>
                                <td width="10%">NA</td>
                                <td width="45%">Maiden Over</td>
                            </tr>
                            <tr>
                                <td width="15%">Bonus Points</td>
                                <td width="10%">NA</td>
                                <td width="10%">4</td>
                                <td width="10%">2</td>
                                <td width="10%">2</td>
                                <td width="45%">4 wickets</td>
                            </tr>
                            <tr>
                                <td width="15%">Bonus Points</td>
                                <td width="10%">NA</td>
                                <td width="10%">8</td>
                                <td width="10%">4</td>
                                <td width="10%">4</td>
                                <td width="45%">5 wickets</td>
                            </tr>
                            <tr>
                                <td width="15%">Economy Rate</td>
                                <td width="10%">1 over</td>
                                <td width="10%">2 overs</td>
                                <td width="10%">5 overs</td>
                                <td width="10%">NA</td>
                                <td width="45%">Minimum overs bowled by player to be applicable</td>
                            </tr>
                            <tr>
                                <td width="15%">Economy Rate</td>
                                <td width="10%">NA</td>
                                <td width="10%">1</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">Between 6 and 5 runs per over</td>
                            </tr>
                            <tr>
                                <td width="15%">Economy Rate</td>
                                <td width="10%">NA</td>
                                <td width="10%">2</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">Between 4.99 and 4 runs per over</td>
                            </tr>
                            <tr>
                                <td width="15%">Economy Rate</td>
                                <td width="10%">NA</td>
                                <td width="10%">3</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">Below 4 runs per over</td>
                            </tr>
                            <tr>
                                <td width="15%">Economy Rate</td>
                                <td width="10%">NA</td>
                                <td width="10%">-1</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">Between 9 and 10 runs per over</td>
                            </tr>
                            <tr>
                                <td width="15%">Economy Rate</td>
                                <td width="10%">NA</td>
                                <td width="10%">-2</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">Between 10.01 and 11 runs per over</td>
                            </tr>
                            <tr>
                                <td width="15%">Economy Rat</td>
                                <td width="10%">NA</td>
                                <td width="10%">-3</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">Above 11 runs per over</td>
                            </tr>
                            <tr>
                                <td width="15%">Economy Rat</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">1</td>
                                <td width="10%">NA</td>
                                <td width="45%">Between 4.5 and 3.5 runs per over</td>
                            </tr>
                            <tr>
                                <td width="15%">Economy Rate</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">2</td>
                                <td width="10%">NA</td>
                                <td width="45%">Between 3.49 and 2.5 runs per over</td>
                            </tr>
                            <tr>
                                <td width="15%">Economy Rate</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">3</td>
                                <td width="10%">NA</td>
                                <td width="45%">Below 2.5 runs per over</td>
                            </tr>
                            <tr>
                                <td width="15%">Economy Rate</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">-1</td>
                                <td width="10%">NA</td>
                                <td width="45%">Between 7 and 8 runs per over</td>
                            </tr>
                            <tr>
                                <td width="15%">Economy Rate</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">-2</td>
                                <td width="10%">NA</td>
                                <td width="45%">Between 8.01 and 9 runs per over</td>
                            </tr>
                            <tr>
                                <td width="15%">Economy Rate</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">-3</td>
                                <td width="10%">NA</td>
                                <td width="45%">Above 9 runs per over</td>
                            </tr>
                            <tr>
                                <td width="15%">Strike Rate</td>
                                <td width="10%">5 balls</td>
                                <td width="10%">10 balls</td>
                                <td width="10%">20 balls</td>
                                <td width="10%">NA</td>
                                <td width="45%">Minimum balls faced by a player to be applicable</td>
                            </tr>
                            <tr>
                                <td width="15%">Strike Rate</td>
                                <td width="10%">NA</td>
                                <td width="10%">-1</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">Between 60 and 70 runs per 100 balls</td>
                            </tr>
                            <tr>
                                <td width="15%">Strike Rate</td>
                                <td width="10%">NA</td>
                                <td width="10%">-2</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">Between 50 and 59.99 runs per 100 balls</td>
                            </tr>
                            <tr>
                                <td width="15%">Strike Rate</td>
                                <td width="10%">NA</td>
                                <td width="10%">-3</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">Below 50 runs per 100 balls</td>
                            </tr>
                            <tr>
                                <td width="15%">Strike Rate</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">-1</td>
                                <td width="10%">NA</td>
                                <td width="45%">Between 50 and 60 runs per 100 balls</td>
                            </tr>
                            <tr>
                                <td width="15%">Strike Rate</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">-2</td>
                                <td width="10%">NA</td>
                                <td width="45%">Between 40 and 49.99 runs per 100 balls</td>
                            </tr>
                            <tr>
                                <td width="15%">Strike Rate</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">-3</td>
                                <td width="10%">NA</td>
                                <td width="45%">Below 40 runs per 100 balls</td>
                            </tr>
                            <tr>
                                <td width="15%">Bonus Points</td>
                                <td width="10%">8</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">2 wickets</td>
                            </tr>
                            <tr>
                                <td width="15%">Bonus Points</td>
                                <td width="10%">16</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">3 wickets</td>
                            </tr>
                            <tr>
                                <td width="15%">Bonus Points</td>
                                <td width="10%">8</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">30 runs scored by a batsman in a single innings</td>
                            </tr>
                            <tr>
                                <td width="15%">Economy Rate</td>
                                <td width="10%">6</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">Below 6 runs per over</td>
                            </tr>
                            <tr>
                                <td width="15%">Economy Rate</td>
                                <td width="10%">4</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">Between 6 and 6.99 runs per over</td>
                            </tr>
                            <tr>
                                <td width="15%">Economy Rate</td>
                                <td width="10%">2</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">Between 7 and 8 runs per over</td>
                            </tr>
                            <tr>
                                <td width="15%">Economy Rate</td>
                                <td width="10%">-2</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">Between 11 and 12 runs per over</td>
                            </tr>
                            <tr>
                                <td width="15%">Economy Rate</td>
                                <td width="10%">-4</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">Between 12.01 and 13 runs per over</td>
                            </tr>
                            <tr>
                                <td width="15%">Economy Rate</td>
                                <td width="10%">-6</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">Above 13 runs per over</td>
                            </tr>
                            <tr>
                                <td width="15%">Strike Rate</td>
                                <td width="10%">-2</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">Between 90 and 100 runs per 100 balls</td>
                            </tr>
                            <tr>
                                <td width="15%">Strike Rate</td>
                                <td width="10%">-4</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">Between 80 and 89.99 runs per 100 balls</td>
                            </tr>
                            <tr>
                                <td width="15%">Strike Rate</td>
                                <td width="10%">-6</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="10%">NA</td>
                                <td width="45%">Below 80 runs per 100 balls</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GameRule;