export const journeyData = [
  {
    icon: "tradingItemOne",
    title: "Real-Time Stats and Updates",
    description:
      "Stay ahead of the game with instant access to live scores, player statistics, and match updates. Never miss a moment with our real-time data feeds, ensuring you make informed decisions at every step.",
  },
  {
    icon: "tradingItemTwo",
    title: "Seamless User Interface",
    description:
      "Enjoy a smooth and intuitive user experience with our state-of-the-art design. Navigate effortlessly through the app, set your fantasy team, and track your performance with ease.",
  },
  {
    icon: "tradingItemThree",
    title: "Custom Leagues and Competitions",
    description:
      "Create and join custom leagues tailored to your preferences. Compete with friends, family, or other cricket enthusiasts in personalized competitions, enhancing the thrill of fantasy cricket.",
  },
  {
    icon: "tradingItemFour",
    title: "Expert Insights and Tips",
    description:
      "Gain a competitive edge with insights from cricket experts. Access professional tips, player analysis, and strategic advice to maximize your team's potential and boost your chances of winning.",
  },
  {
    icon: "tradingItemFive",
    title: "Instant Rewards and Prizes",
    description:
      "Experience the excitement of winning with instant rewards and prizes. Participate in daily and weekly challenges to earn cash prizes, exclusive merchandise, and more, making every match a rewarding experience.",
  },
];

export const chooseData = [
  {
    icon: "ProductItemone",
    title: "Seamless Integration",
    description:
      "Experience the ease of integrating JustPayze into your platform. Our seamless integration process ensures that you can start accepting payments without any hassle. Say goodbye to complex setups and hello to a smoother payment experience for your customers.",
  },
  {
    icon: "ProductItemtwo",
    title: "Dashboard with Real-Time Insights",
    description:
      "Monitor your transactions and gain valuable insights with our feature-rich dashboard. Access real-time big data outputs that you the information needed to make informed decisions. Stay ahead of the curve and optimize your payment processes effortlessly.",
  },
  {
    icon: "ProductItemthree",
    title: "User-Friendly Checkout Pages",
    description:
      "Enhance your customers' experience with our user-friendly checkout pages. We understand the importance of a smooth and intuitive payment process. With JustPayze, you can create a seamless checkout journey that fosters customer satisfaction and loyalty.",
  },
  {
    icon: "ProductItemfour",
    title: "Highest Security Measures at Every Step",
    description:
      "Security is our top priority. Rest easy knowing that JustPayze employs the highest security measures at every step of the payment process. From data encryption to fraud prevention,we've got you covered, ensuring a secure environment for both you and your customers.",
  },
];

export const whyChooseData = [
  {
    icon: "whyChoose1",
    title: "Exclusive Rewards and Bonuses",
    description:
      "Enjoy a range of rewards and bonuses designed to enhance your gameplay. From welcome bonuses to referral rewards and daily challenges, CrickStarz11 offers numerous opportunities to earn extra points and prizes, keeping the excitement alive.",
  },
  {
    icon: "whyChoose2",
    title: "Comprehensive Player Data",
    description:
      "Access detailed player statistics, historical performance data, and real-time updates to make informed decisions. Our extensive database helps you choose the best players and create a winning strategy.",
  },
  {
    icon: "whyChoose3",
    title: "24/7 Customer Support",
    description:
      "Our dedicated customer support team is available around the clock to assist you with any queries or issues. Whether you need help setting up your team or have questions about the game rules, we're here to provide prompt and reliable assistance.",
  },
];

export const accordionFullData = [
  {
    id: "flush-headingOne",
    title: "What is Crickstarz11?",
    content:
      "Crickstarz11 is a fantasy sports app where users can create and join fantasy leagues, draft players, and compete against others based on real-world sports performance..",
  },
  {
    id: "flush-headingTwo",
    title: "How do I get started with Crickstarz11?",
    content:
      "Download the Crickstarz11 app from the Download App Button and create an account, then join or create a league to start playing.",
  },
  {
    id: "flush-headingThree",
    title: "How do I create an account?",
    content: `Click on the "Sign Up" button on the homepage of the app, enter your details, and follow the prompts to create your account.`,
  },
  {
    id: "flush-headingFour",
    title: "I forgot my password. How can I reset it?",
    content: `Click on the "Forgot Password" link on the login page and follow the instructions to reset your password.`,
  },
  {
    id: "flush-headingFive",
    title: "How do I join a league?",
    content: `After creating an account, you can search for public leagues or enter a league code if you have one to join a private league.
`,
  },
  {
    id: "flush-headingSix",
    title: "Can I create my own league?",
    content: `Yes, you can create your own league by clicking on the "Create League" button and following the setup instructions.`,
  },
  {
    id: "flush-headingSeven",
    title: "How many teams can I have in a league?",
    content: `The number of teams per league can vary depending on the league settings. Typically, leagues can have between 8 to 16 teams.`,
  },
  {
    id: "flush-headingEight",
    title: "How does the draft work?",
    content: `The draft can be conducted in different formats such as live online, offline, or auto-pick. You can choose your preferred method during league setup.`,
  },
];

export const clientReview = [
  {
    icon: "startPlay2",
    title: "Dairy & Bakery",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Grocery",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Ayurvedic",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
];
