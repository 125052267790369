import React, { useState } from "react";
import Button from "../../ui/elements/Button";
import axios from "axios";
import { IoReload } from "react-icons/io5";
import { Icon } from "../../ui";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    setLoading(true);
    try {
      await axios.post("http://192.168.132.77:8000/contactus", formData);
      alert("Form submitted successfully!");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      });
    } catch (error) {
      alert("There was an error submitting the form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="policy_text">
          <div className="row align-items-center contact_main">
            <div className="col-lg-6">
              <div className="mxprofit-hero">
                <Icon name="contactUs" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="commantext contact_text">
                <h4>Contact Us</h4>
                <p>
                <Icon name="CompnayBlack" className="policy" />
                </p>
                <p>
                  <b>Address: </b>Shop No.11, Dadu Dayal, CD Block, Kalyanpura, Mansarovar, Jaipur,Rajasthan, India, 302020
                </p>
                <p>
                  <b>CIN-</b>U62012RJ2024PTC096740
                </p>
                 <p>
                  <b>GST - </b> 08AAGCJ5532R1ZX
                </p>
                <p>
                  <b>Phone - </b>
                  <a href="tel:+919007574215">+91 9007574215</a>
                </p>
                <p className="emailspace">
                  <b>Email - </b>
                  <a href="mailto:contact@crickstarz11.com">
                    contact@crickstarz11.com
                  </a>
                </p>
              </div>
              <form onSubmit={handleSubmit} className="contact_form">
                <div className="form_field">
                  <label htmlFor="firstName">First Name*</label>
                  <input
                    type="text"
                    name="firstName"
                    className="form-control"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </div>
                <div className="form_field">
                  <label htmlFor="lastName">Last Name*</label>
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </div>
                <div className="form_field">
                  <label htmlFor="email">Email*</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form_field">
                  <label htmlFor="phoneNumber">Phone Number*</label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    className="form-control"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  />
                </div>
                <Button
                  className="primarybtn w-100"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <IoReload className="animate-spin" /> : "Submit"}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
