import React from "react";
import { Icon } from "../../ui";
import { accordionFullData, journeyData, whyChooseData } from "./mock";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import { NavLink } from "react-router-dom";
import apk from "../../apk/crickstarz11.apk";
import Button from "../../ui/elements/Button";

const Home = () => {
  return (
    <>
      {/* NAVBAR */}

      <section id="home">
        <div className="lightbg position-relative">
          <div className="container">
            <div className="hero-sec">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-6  my-2">
                  <h4>
                    <span></span>Welcome to Our CrickStarz11
                  </h4>
                  <h1>
                    Dominate the Fantasy Cricket World with
                    <span> CrickStarz11</span>
                  </h1>
                  <p>
                    Welcome to Crickstarz11.com, Join Crickstarz11 to build your
                    dream cricket team, compete in exciting fantasy leagues, and
                    win big! With live updates, expert insights, and seamless
                    gameplay, experience cricket like never before.
                  </p>
                  <div className="mybtn d-flex">
                    <Button
                      className="primarybtn"
                      // onClick={() => {
                      //   handleLinkClick("usp");
                      // }}
                    >
                      Get Started
                    </Button>
                    {/* <a href="#contact" className="greenbtn">
                      Know More
                    </a> */}
                  </div>
                </div>
                <div className="col-lg-6 my-2">
                  <div className="heroimg text-lg-center text-start">
                    <Icon name="Heroimg" className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className=" justify-content-center "></div>
            </div>
          </div>
        </div>
      </section>

      <section className="container" id="usp">
        <div className="start_withmain comman_space">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>
                  Our <span>Features </span>
                </h4>
                <p>
                  Unlock real-time stats, expert insights, and instant rewards
                  with CrickStarz11. Experience a seamless, custom fantasy
                  cricket journey like never before.
                </p>
              </div>
            </div>
          </div>

          <div className="swipermain">
            <Swiper
              centeredSlides={false}
              // centeredSlides={true}
              spaceBetween={15}
              speed={900}
              initialSlide={1}
              parallax
              rewind
              loop
              effect="slide"
              freeMode
              grabCursor={true}
              watchSlidesProgress
              slidesPerView={3}
              breakpoints={{
                1200: { slidesPerView: 3 },
                992: { slidesPerView: 2.5 },
                576: { slidesPerView: 1.8 },
                425: { slidesPerView: 1.2 },
                0: { slidesPerView: 1.2 },
              }}
              autoplay={{
                delay: 19000,
              }}
              // pagination={{ clickable: true }}
              // modules={[Autoplay, Pagination]}
              pagination={{
                clickable: true,
                el: ".swiper-pagination", // Target the pagination element
              }}
              modules={[Pagination]}
            >
              {journeyData?.map((data, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      key={index}
                      className="start_withbox"
                      // style={{ animationDelay: `${index + 0.1}s` }}
                    >
                      <div className="start_withicon">
                        <Icon name={data?.icon} className="img-fluid" />
                      </div>
                      <div>
                        <h5>{data?.title}</h5>
                        <p>{data?.description}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="swiper-pagination"></div>
          </div>

          {/* <div className="start_withsub">
            {journeyData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="start_withbox"
                  // style={{ animationDelay: `${index + 0.1}s` }}
                >
                  <div className="start_withicon">
                    <Icon name={item?.icon} className="img-fluid" />
                  </div>
                  <div>
                    <h5>{item?.title}</h5>
                    <p>{item?.description}</p>
                  </div>
                </div>
              );
            })}
          </div> */}
        </div>
      </section>
      <div className="container">
        <section id="about">
          <div className="row align-items-center maximum_profirsec">
            <div className="col-lg-7">
              <div className="aboutus_text">
                <h4>About US</h4>
                <p>Crickstarz11.com is an online fantasy game designed
                  for the fans who like to showcase their cricket knowledge and
                  analytical skills.
                </p>
                <p>
                  Here, you don a selector’s hat and create a playing XI of your
                  choice – a set of players you think will keep the scorers busy
                  and spectators on the edge of their seats. Here, you trust
                  your skills and create your own fortune – all with the help of
                  on-field players you placed your trust in.
                </p>
                <p>
                  You aren’t a mere spectator anymore. You get to be the manager
                  of your team.
                </p>
                <p>
                  Crickstarz11.com is managed by a professional management team
                  with several years of experience in the online games industry.
                  To find out more about the job opportunities, work culture and
                  the life at out platform.
                </p>
                <NavLink to={"/about-us"} className="primarybtn d-inline-block">
                  Know More
                </NavLink>
              </div>
            </div>
            <div className="col-lg-5 ">
              <div className="aboutus_img text-lg-center text-start">
                <Icon name="aboutus" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>
        <section id="whychoose" className="comman_space">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>
                  Why Choose <span>us ?</span>
                </h4>
                <p>
                  Join us for expert insights, real-time updates, custom
                  leagues, and instant rewards in fantasy cricket.
                </p>
              </div>
            </div>
          </div>
          <div className="why_chooseboxmain">
            {whyChooseData.map((item, index) => (
              <div className="why_choosebox">
                <div className="whychoose_icon">
                  <Icon name={item?.icon} className="img-fluid" />
                </div>
                <div>
                  <h5>{item?.title}</h5>
                  <p>{item?.description}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* <section id="contact">
          <div className="contact_main">
            <div className="d-md-flex justify-content-between align-items-center d-block">
              <div className="contact_text">
                <h4>Contact Us</h4>
                <p>Get in touch with our team to learn more about</p>
                <Button className="primarybtn">Connect With Us</Button>
              </div>
              <div className="contact_icon">
                <Icon name="earn2" className="img-fluid" />
              </div>
            </div>
          </div>
        </section> */}

        {/* <div className="categories_swiper">
          <Swiper
            centeredSlides={false}
            spaceBetween={20}
            speed={900}
            parallax
            rewind
            loop
            effect="slide"
            freeMode
            grabCursor={true}
            watchSlidesProgress
            slidesPerView={3}
            breakpoints={{
              1200: { slidesPerView: 4 },
              992: { slidesPerView: 3 },
              768: { slidesPerView: 3 },
              576: { slidesPerView: 2 },
              0: { slidesPerView: 1.5 },
            }}
            autoplay={{
              delay: 19000,
            }}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination]}
          >
            {clientReview?.map((data, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="category_card ">
                    <Icon name="earn1" className="" />
                    <div className="play_cardtext">
                      <h4>{data?.title}</h4>
                      <p>{data?.productNo}</p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div> */}
      </div>

      <section>
        <div className="get_app">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-4 col-md-8">
                <h4>Get the App Now!</h4>
                <p>
                  Unlock a world of cricket fantasy excitement with the
                  Crickstarz11 app. Enjoy seamless gameplay, real-time updates,
                  and exclusive rewards, all at your fingertips. Download today!
                </p>
              </div>
              <div className="col-lg-4 col-md-4 text-md-end text-sm-start">
                <a
                  type="button"
                  className="primarybtn"
                  href={apk}
                  download="crickstarz11.apk"
                >
                  Download App Free
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="comman_space">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>
                  FAQ’s About <span>Crickstarz11.com </span>
                </h4>
                <p>
                  Find quick answers to common questions about Crickstarz11,
                  covering gameplay, account management, and rules for a
                  seamless experience.
                </p>
              </div>
            </div>
          </div>
          <div
            className="accordion accordion-flush selfaccord"
            id="accordionFlushExample"
          >
            {accordionFullData?.slice(0, 4)?.map((item, index) => (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id={item.id}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${index}1`}
                    aria-expanded="false"
                    aria-controls={`flush-collapse${index}1`}
                  >
                    {item.title}
                  </button>
                </h2>
                <div
                  id={`flush-collapse${index}1`}
                  className="accordion-collapse collapse"
                  aria-labelledby={item.id}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">{item.content}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* <Footer /> */}
    </>
  );
};

export default Home;
